@import '../variables';

html, body, mat-sidenav-container, .content {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    width: 100%;
    min-height: 100vh;
}

mat-sidenav {
    width: 280px;
}

mat-spinner.btn {
    height: 36px;
}

.container {
    margin: 16px;
}

.app-action {
    z-index: 1000;
    position: fixed;
    right: 2%;
    bottom: 3%;
}

button.fab-no-shadow {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

button.fab-no-shadow :hover :active {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none!important;
    box-shadow: none !important;
}


button.attended {
    background-color: #4CAF50;
    color: white;
}


mat-dialog-container.mat-dialog-container{
    padding: 0;
}

mat-dialog-container > app-schedule-detail > mat-card{
    margin: 0;
}

table {
    &.mat-table,&.material{
        width: 100%;
        border-collapse: collapse;
    }

    tr {
        height: 48px;
    }
}

.app-actions {
    margin: 16px 0 16px 0;
}

.text-center {
    text-align: center;
}

[hidden] {
    display: none !important;
}

.actionable {
    transition: background-color 0.25s ease;
    cursor: pointer;

    &:hover{
        background-color: rgba(0, 0, 0, 0.04);
    }
}
